import React, { useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { BlockUI } from "primereact/blockui";

import { useReactToPrint } from "react-to-print";
import { ComponentToPrint } from "./ComponentToPrint";

export const RecieptPrintDialog = (props) => {
   const { saleData } = props;
   const [isLoading, setIsLoading] = useState(false);
   const componentRef = useRef();
   const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      copyStyles: false,
      pageStyle: "@page { size: 2.5in 4in }"
   });

   const dialogFooter = (
      <>
         <Button
            loading={isLoading}
            label="Cancel"
            icon="pi pi-times"
            className="p-button-text"
            style={{ color: "black", border: "1px solid black" }}
            onClick={() => props.toggleFn(false)}
         />

         <Button
            loading={isLoading}
            label="Print"
            icon="pi pi-check"
            className="p-button-text"
            style={{
               color: "#FFEB3B",
               border: "1px solid black",
               backgroundColor: "black"
            }}
            onClick={handlePrint}
         />
      </>
   );

   return (
      <Dialog
         footer={dialogFooter}
         visible={props.visible}
         style={{ width: "600px" }}
         header="Print"
         onHide={() => props.toggleFn(false)}
         closable
         modal
         className="p-fluid"
      >
         <BlockUI blocked={isLoading}>
            <>
               <BlockUI />
               {saleData != null && <ComponentToPrint saleObject={saleData} ref={componentRef} />}
            </>
         </BlockUI>
      </Dialog>
   );
};
