export default {
   //Custom
   ACCESS_TOKEN: "@access_token",
   PERMISSIONS: "@permissions",
   REFRESH_TOKEN: "@refresh_token",
   EXPO_DEVICE_ID: "@expo_device_id",
   IS_LOGGED_IN: "@is_logged_in",
   USER_DETAILS_JSON: "@user_details_json",
   FULL_LOGIN_DETAILS_JSON: "@full_login_details_json",
   USERNAME: "@username",
   FULL_NAME: "@full_name",
   COUNTRY: "@country",
   LANGUAGE: "@language",
   IS_SUPER_ADMIN: "@is_super_admin",
   IS_SHOP_ATTENDANT: "@is_shop_attendant",
   IS_SHOP_OWNER: "@is_shop_owner",
   USER_SETTINGS: "@user_settings",
   IS_SHOP_OWNER_ID: "@is_shop_owner_id",
   LOGIN_TIME: "@login_time",
   SHOP_ID: "@shop_id",
   CURRENCIES: "@CURRENCIES",
   HELD_TRANSACTIONS: "@held_transactions",
   DEBT: "@DEBT",
   BUTTON_OPTIONS: "@button_options",
};
