import { showErrorMessage, showSuccessMessage } from "constants/ErrorMessages";
import Manufacturer from "pages/administrator/Manufacturer";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { BaseApiService } from "utils/BaseApiService";
import { SETTINGS_ENDPOINT } from "utils/EndpointsUtils";

export default function Settings() {
   const [settings, setSettings] = useState(null);
   const [appName, setAppName] = useState("");
   const [appLogoUrl, setAppLogoUrl] = useState("");
   const [smsGatewayUsername, setSmsGatewayUsername] = useState("");
   const [smsGatewayPassword, setSmsGatewayPassword] = useState("");
   const [paymentGatewayUsername, setPaymentGatewayUsername] = useState("");
   const [paymentGatewayPassword, setPaymentGatewayPassword] = useState("");
   const [smtpAddress, setSmtpAddress] = useState("");
   const [smtpPassword, setSmtpPassword] = useState("");
   const [smtpPort, setSmtpPort] = useState("");
   const [smtpUsername, setSmtpUsername] = useState("");
   const [smtpHost, setSmtpHost] = useState("");
   const [manufacturers, setManufacturers] = useState([]);
   const [packageUnits, setPackageUnits] = useState([]);
   const [categories, setCategories] = useState([]);
   const [containerUnits, setContainerUnits] = useState([]);
   const [loading, setLoading] = useState(false);

   const toast = useRef(null);

   const [selectedManufacturer, setSelectedManufacturer] = useState(null);
   const [selectedContainerUnit, setSelectedContainerUnit] = useState(null);
   const [selectedPackageUnit, setSelectedPackageUnit] = useState(null);
   const [selectedCategory, setSelectedCategory] = useState(null);

   const getSettings = async () => {
      await new BaseApiService(SETTINGS_ENDPOINT)
         .getRequestWithJsonResponse()
         .then((response) => {
            setSettings(response?.data);
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const fillSettings = () => {
      setAppLogoUrl(settings?.appLogoUrl);
      setAppName(settings?.appName);
      setSmsGatewayUsername(settings?.smsGatewayUsername);
      setSmsGatewayPassword(settings?.smsGatewayPassword);
      setPaymentGatewayUsername(settings?.paymentGatewayUsername);
      setPaymentGatewayPassword(settings?.paymentGatewayPassword);
      setSmtpAddress(settings?.smtpAddress);
      setSmtpPassword(settings?.smtpPassword);
      setSmtpPort(settings?.smtpPort);
      setSmtpUsername(settings?.smtpUsername);
      setSmtpHost(settings?.smtpHost);
      setSelectedContainerUnit(containerUnits.find((unit) => unit.id === settings?.defaultProductContainerUnit?.id));
      setSelectedPackageUnit(packageUnits.find((unit) => unit.id === settings?.defaultProductPackageUnit?.id));
      setSelectedCategory(categories.find((category) => category.id === settings?.defaultProductCategory?.id));
      setSelectedManufacturer(manufacturers.find((manufacturer) => manufacturer.id === settings?.defaultProductManufacturer?.id));      
   };

   const saveSettings = async () => {
      const payload = {
         appName: appName,
         appLogoUrl: appLogoUrl,
         smsGatewayUsername: smsGatewayUsername,
         smsGatewayPassword: smsGatewayPassword,
         paymentGatewayUsername: paymentGatewayUsername,
         paymentGatewayPassword: paymentGatewayPassword,
         smtpAddress: smtpAddress,
         smtpPassword: smtpPassword,
         smtpHost: smtpHost,
         smtpPort: smtpPort,
         smtpUsername: smtpUsername,
         defaultProductManufacturerId: selectedManufacturer?.id,
         defaultProductPackageUnitId: selectedPackageUnit?.id,
         defaultProductCategoryId: selectedCategory?.id,
         defaultProductContainerUnitId: selectedContainerUnit?.id
      };

      if (!loading) {
         setLoading(true);
         await new BaseApiService(SETTINGS_ENDPOINT)
            .postRequestWithJsonResponse(payload)
            .then((response) => {
               setSettings(response);
               showSuccessMessage(toast, "Settings saved successfully");
               setLoading(false);
            })
            .catch((e) => {
               showErrorMessage(toast, e?.message);
               setLoading(false);
            });
      }
   };

   const fetchManufacturers = () => {
      let searchParameters = { searchTerm: "", offset: 0, limit: 1000 };
      new BaseApiService("/manufacturers")
         .getRequestWithJsonResponse(searchParameters)
         .then((response) => {
            setManufacturers(response.records);
         })
         .catch((error) => {});
   };

   const fetchContainerUnits = async () => {
      let searchParameters = {
         searchTerm: "",
         offset: 0,
         limit: 100,
         commaSeparatedTypeIds: [2]
      };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            const mod = response.records.map((item) => {
               return {
                  id: item.id,
                  name: item.value,
               };
            });
            setContainerUnits(mod);
         })
         .catch((error) => {});
   };
   const fetchCategories = async () => {
      let searchParameters = {
         searchTerm: "",
         offset: 0,
         limit: 100,
         commaSeparatedTypeIds: [3]
      };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            const mod = response.records.map((item) => {
               return {
                  id: item.id,
                  name: item.value,
               };
            });
            setCategories(mod);
         })
         .catch((error) => {});
   };

   const fetchPackageUnits = async () => {
      let searchParameters = {
         searchTerm: "",
         offset: 0,
         limit: 100,
         commaSeparatedTypeIds: [1]
      };
      new BaseApiService("/lookups/lookup-values")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            const mod = response.records.map((item) => {
               return {
                  id: item.id,
                  name: item.value,
               };
            });
            setPackageUnits(mod);
         })
         .catch((error) => {});
   };

   useEffect(() => {
      getSettings();
      fetchContainerUnits();
      fetchManufacturers();
      fetchPackageUnits();
      fetchCategories();
   }, []);

   useEffect(() => {
      if (settings) {
         fillSettings();
      }
   }, [settings, manufacturers, packageUnits, containerUnits, categories]);

   return (
      <div className="card p-fluid min-h-full">
         <Toast ref={toast} />
         <h4 className="mb-5">Application settings</h4>

         <div className="formgrid grid ">
            <div className="field col-12 md:col-6 lg:col-3">
               <label>App name</label>

               <InputText value={appName} onChange={(e) => setAppName(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Logo Url</label>

               <InputText value={appLogoUrl} onChange={(e) => setAppLogoUrl(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Sms Gateway Username</label>

               <InputText value={smsGatewayUsername} onChange={(e) => setSmsGatewayUsername(e.target.value)} name="smsm" />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Sms Gateway Password</label>

               <Password feedback={false} toggleMask value={smsGatewayPassword} onChange={(e) => setSmsGatewayPassword(e.target.value)} name="smsm" />
            </div>
         </div>

         <div className="formgrid grid ">
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Payment Gateway Username</label>

               <InputText value={paymentGatewayUsername} onChange={(e) => setPaymentGatewayUsername(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Payment Gateway Password</label>

               <Password feedback={false} toggleMask value={paymentGatewayPassword} onChange={(e) => setPaymentGatewayPassword(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Smtp Address</label>

               <InputText value={smtpAddress} onChange={(e) => setSmtpAddress(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Smtp Password</label>

               <Password feedback={false} toggleMask value={smtpPassword} onChange={(e) => setSmtpPassword(e.target.value)} />
            </div>
         </div>

         <div className="formgrid grid ">
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Smtp Host</label>

               <InputText value={smtpHost} onChange={(e) => setSmtpHost(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Smtp Port</label>

               <InputText value={smtpPort} onChange={(e) => setSmtpPort(e.target.value)} />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Smtp Username</label>

               <InputText value={smtpUsername} onChange={(e) => setSmtpUsername(e.target.value)} />
            </div>
         </div>

         <div className="formgrid grid ">
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Default Product Manufacturer</label>

               <Dropdown
                  filter
                  value={selectedManufacturer}
                  optionLabel="name"
                  options={manufacturers}
                  placeholder="Select Manufacturer"
                  onChange={(e) => {
                     setSelectedManufacturer(e.value);
                  }}
               />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Default Product Category</label>

               <Dropdown
                  filter
                  value={selectedCategory}
                  optionLabel="name"
                  options={categories}
                  placeholder="Select Category"
                  onChange={(e) => setSelectedCategory(e.value)}
               />
            </div>

            <div className="field col-12 md:col-6 lg:col-3">
               <label>Default Package Unit</label>

               <Dropdown
                  filter
                  optionLabel="name"
                  options={packageUnits}
                  value={selectedPackageUnit}
                  placeholder="Select Package Unit"
                  onChange={(e) => setSelectedPackageUnit(e.value)}
               />
            </div>
            <div className="field col-12 md:col-6 lg:col-3">
               <label>Default Container Unit</label>

               <Dropdown
                  filter
                  optionLabel="name"
                  options={containerUnits}
                  value={selectedContainerUnit}
                  placeholder="Select Container Unit"
                  onChange={(e) => setSelectedContainerUnit(e.value)}
               />
            </div>
         </div>

         <div className="formgrid grid flex flex-row-reverse flex-wrap mt-3">
            <div className="field col-12 md:col-6 lg:col-2 ">
               <Button label={"Save"} icon="pi pi-save" className="primary-btn" onClick={saveSettings} loading={loading} />
            </div>
         </div>
      </div>
   );
}
