import React, { useEffect, useRef, useState } from "react";
import { formatNumberWithCommas, hasNull, toReadableDate, toReadableTime } from "../../utils/Utils";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { BaseApiService } from "utils/BaseApiService";
import { SHOP_SALES_ENDPOINT } from "utils/EndpointsUtils";
import { Password } from "primereact/password";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages";
import { showErrorMessage } from "constants/ErrorMessages";
import FormFooter from "components/FormFooter";
import { useSelector } from "react-redux";
import { getCanViewShopIncome } from "duqactStore/selectors";

function SaleSummaryDialog({ hideDialog, visible, selectedSale, reload, credit }) {
   const [itemCount, setItemCount] = useState(0);
   const [totalProfit, setTotalProfit] = useState(0);
   const [selectedProducts, setSelectedProducts] = useState(null);
   const [reason, setReason] = useState(null);
   const [password, setPassword] = useState(null);
   const [loading, setLoading] = useState(false);
   const [submitted, setSubmitted] = useState(false);
   const [delId, setDelId] = useState(null);

   const payload = { reason, password };

   const canViewIncome = useSelector(getCanViewShopIncome);

   const cancelledItems = selectedSale?.lineItems?.filter((item) => item?.status === "CANCELLED");

   const dialogMessage = useRef(null);

   const hide = () => {
      setSelectedProducts(null);
      setLoading(false);
      setSubmitted(false);
      setDelId(null);
      hideDialog();
   };

   const cancelSale = async () => {
      setLoading(true);
      await new BaseApiService(`${SHOP_SALES_ENDPOINT}/${selectedSale?.id}/cancel`)
         .postRequestWithJsonResponse(payload)
         .then((r) => {
            setDelId(null);
            setLoading(false);
            hide();
            reload();
         })
         .catch((e) => {
            console.error(e);
            showErrorMessage(dialogMessage, e?.message);
            setLoading(false);
         });
   };

   const cancelLineItem = async () => {
      let id = selectedProducts?.id;
      setLoading(true);
      await new BaseApiService(`${SHOP_SALES_ENDPOINT}/line-item/${id}/cancel`)
         .postRequestWithJsonResponse(payload)
         .then((r) => {
            setDelId(null);
            setLoading(false);
            hide();
            reload();
         })
         .catch((e) => {
            console.error(e);
            showErrorMessage(dialogMessage, e?.message);
            setLoading(false);
         });
   };

   const deleteRecord = (id) => {
      setSubmitted(true);
      if (!hasNull(payload)) {
         if (delId == 0) {
            cancelSale();
         }
         if (delId == 1) {
            cancelLineItem();
         }
      }
   };

   useEffect(() => {
      if (selectedSale !== null) {
         const { lineItems } = selectedSale;
         let items = lineItems.reduce((a, item) => a + item?.quantity, 0);
         let cartProfit = lineItems.reduce((a, i) => a + i.totalProfit, 0);

         setItemCount(items);
         setTotalProfit(Math.round(cartProfit));
      }
   }, [selectedSale]);

   const unitCostBodyTemplate = (rowData) => {
      return <span className={`${rowData?.status === "CANCELLED" ? "line-through" : ""}`}>{formatNumberWithCommas(rowData?.unitCost)}</span>;
   };

   const totalCostBodyTemplate = (rowData) => {
      return <span className={`${rowData?.status === "CANCELLED" ? "line-through" : ""}`}>{formatNumberWithCommas(rowData?.totalCost)}</span>;
   };

   const nameBodyTemplate = (rowData) => {
      const { saleUnitName, shopProductName } = rowData;
      let unitName = saleUnitName ? " - " + saleUnitName : "";
      return <span className={`${rowData?.status === "CANCELLED" ? "line-through" : ""}`}>{shopProductName + unitName}</span>;
   };

   const qtyBodyTemplate = (rowData) => {
      return <span className={`${rowData?.status === "CANCELLED" ? "line-through" : ""}`}>{formatNumberWithCommas(rowData?.quantity)}</span>;
   };

   return (
      <Dialog
         visible={visible}
         style={{ width: "700px" }}
         header="Sale Summary"
         modal
         className="p-fluid"
         onHide={hide}
         footer={
            <div>
               {selectedSale?.statusName !== "Cancelled" && cancelledItems?.length < selectedSale?.lineItems?.length && (
                  <Button label="Cancel Sale" onClick={() => setDelId(0)} className={`outline-btn ${credit ? 'hidden' : ''}`} />
               )}
               {selectedProducts && <Button label={`Cancel line item`} onClick={() => setDelId(1)} className="primary-btn" />}
            </div>
         }
      >
         <div className="field">
            <div className="flex flex-column">
               <div className=" flex justify-content-between">
                  <span>
                     <span className=" text-2xl">{selectedSale?.shopName}</span>
                     <br />
                     <span className="text-sm"> SN: {selectedSale?.serialNumber}</span>
                  </span>

                  {selectedSale?.attendantDailyReceiptCount && (
                     <div class="counter">
                        <h2 class="noMargin">{selectedSale?.attendantDailyReceiptCount}</h2>
                     </div>
                  )}
               </div>

               <div className="flex justify-content-between">
                  <span className="text-sm font-semibold">
                     {toReadableDate(selectedSale?.dateCreated)}
                  </span>
                     <span className="text-sm font-semibold"> {toReadableTime(selectedSale?.dateCreated)}</span>
               </div>
            </div>

            <DataTable
               value={selectedSale?.lineItems}
               dataKey="id"
               className={selectedSale?.statusName === "Cancelled" ? " line-through" : ""}
               emptyMessage="No items for sale found."
               size="small"
               resizableColumns
               selectionMode={"radiobutton"}
               selection={selectedProducts}
               onSelectionChange={(e) => {
                  if (e.value?.status !== "CANCELLED") {
                     setSelectedProducts(e.value);
                  }
               }}
            >
               {selectedSale?.statusName !== "Cancelled" && cancelledItems?.length < selectedSale?.lineItems?.length && (
                  <Column selectionMode="single" headerStyle={{ width: "3rem" }} />
               )}
               <Column field="shopProductName" header="Item" body={nameBodyTemplate} className="text-left" />
               <Column field="quantity" header="Qty" className="text-center" body={qtyBodyTemplate} />
               <Column field="unitCost" body={unitCostBodyTemplate} header="Cost" className="text-right" />
               <Column field="totalCost" body={totalCostBodyTemplate} header="Amount" className="text-right" />
            </DataTable>

            <div className="px-2">
               <div className="flex justify-content-between mt-2">
                  <span>Total</span>
                  <span className="text-sm font-semibold">
                     {selectedSale?.currency} <span className="text-lg font-semibold">{formatNumberWithCommas(selectedSale?.totalCost)}</span>
                  </span>
               </div>
               <div className="flex justify-content-between mt-4">
                  <span>Recieved</span>
                  <span className="text-sm font-semibold">
                     {selectedSale?.currency} <span className="text-lg font-semibold">{formatNumberWithCommas(selectedSale?.amountPaid)}</span>
                  </span>
               </div>
               <div className="flex justify-content-between mt-2">
                  <span>
                     Purchased
                     <span className="text-lg font-semibold">
                        {" "}
                        {itemCount} {itemCount > 1 ? "items" : "item"}
                     </span>
                  </span>
                  <span className="text-sm font-semibold">
                     <span className="text-sm font-semibold">
                        {selectedSale?.currency}
                        <span className="text-lg font-semibold">{formatNumberWithCommas(selectedSale?.totalCost)}</span>
                     </span>
                  </span>
               </div>
               <div className="flex justify-content-between mt-2">
                  <span>Balance</span>
                  <span className="text-sm font-semibold">
                     {selectedSale?.currency} <span className="text-lg font-semibold">{formatNumberWithCommas(selectedSale?.balanceGivenOut)}</span>
                  </span>
               </div>
               {canViewIncome && (
                  <div className="flex justify-content-between mt-2">
                     <span>Income</span>
                     <span className="text-sm font-semibold">
                        {selectedSale?.currency} <span className="text-lg font-semibold">{formatNumberWithCommas(totalProfit)}</span>
                     </span>
                  </div>
               )}

               {selectedSale?.clientName && (
                  <div className="flex justify-content-between mt-2">
                     <span>Client name</span>
                     <span className=" font-semibold">{selectedSale?.clientName}</span>
                  </div>
               )}

               {selectedSale?.clientPhoneNumber && (
                  <div className="flex justify-content-between mt-2">
                     <span>Client number</span>
                     <span className="font-semibold">{selectedSale?.clientPhoneNumber}</span>
                  </div>
               )}

               <div className="flex justify-content-between mt-2">
                  <span> Served by:</span>
                  <span className="font-semibold">{selectedSale?.createdByFullName}</span>
               </div>
            </div>

            <Dialog visible={delId !== null} className={"p-fluid"} style={{ width: "450px" }} modal onHide={() => setDelId(null)}>
               <div className="flex align-items-center justify-content-center">
                  <h5 className="font-bold">Confirm</h5>
               </div>
               <div className="field flex align-items-center justify-content-center">
                  <span className="text-center">Are you sure you want cancel this record?</span>
               </div>
               <Messages ref={dialogMessage} style={{ width: "100%" }} />

               <div className="field">
                  <label htmlFor="batchNumber">Reason</label>
                  <InputTextarea id="batchNumber" value={reason} onChange={(e) => setReason(e.target.value)} />
                  {submitted && !reason && <small className="">Reason is required.</small>}
               </div>
               <div className="field">
                  <label htmlFor="expiryDate">Password</label>
                  <Password feedback={false} value={password} onChange={(e) => setPassword(e.target.value)} toggleMask />
                  {submitted && !password && <small className="">Password is required.</small>}
               </div>
               <div className="flex justify-content-between gap-3">
                  <Button label="Cancel" icon="pi pi-trash" className="primary-btn" onClick={deleteRecord} loading={loading} />
               </div>
            </Dialog>
         </div>
      </Dialog>
   );
}

export default SaleSummaryDialog;
