import StorageParams from "constants/StorageParams";
import { Menu } from "primereact/menu";
import { useRef, useState } from "react";

const SplitButton = ({ items, saveSale, printAndSave, ...props }) => {
   const [key, setKey] = useState(parseInt(localStorage.getItem(StorageParams.BUTTON_OPTIONS)) || 0);
   const menuRight = useRef(null);

   const reviewed = items.map((element) => {
      const reviewedElement = {
         label: element.label,
         icon: element.icon,
         key: element.key,
         command: () => {
            setKey(element.key);
            localStorage.setItem(StorageParams.BUTTON_OPTIONS, element.key);
         }
      };

      return reviewedElement;
   });

   return (
      <>
         <div {...props} className="flex bg-black-alpha-90 border-round m-0 ">
            <Menu
               model={reviewed.filter((element) => {
                  return element.key !== key;
               })}
               popup
               ref={menuRight}
               id="popup_menu_right"
            />
            <button
               className=" w-3rem  bg-black-alpha-90 text-yellow-100 hover:bg-white-alpha-20 border-none border-round-left m-0"
               onClick={(event) => menuRight.current.toggle(event)}
               aria-controls="popup_menu_right"
               aria-haspopup
            >
               <i className={"pi pi-chevron-down"} />
            </button>
            <button className=" bg-black-alpha-90 hover:bg-white-alpha-20 text-yellow-100 border-none border-round-right text-xl m-0"
            onClick={() => {
                if (key === 0) {
                  saveSale();
                } else if (key === 1) {
                  printAndSave();
                }
            }}
            >
               {reviewed[key]?.label}
               <i className={`${reviewed[key]?.icon} ml-2 mr-3`} />
            </button>
         </div>
      </>
   );
};

export default SplitButton;
