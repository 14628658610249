import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages";
import React, { useEffect, useRef, useState } from "react";
import { InputNumber } from "primereact/inputnumber";
import { classNames } from "primereact/utils";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { hasNull } from "../../utils/Utils";
import { InputText } from "primereact/inputtext";
import { BaseApiService } from "../../utils/BaseApiService";

export default function StockListingFormDialog({ productDialog, hideDialog, selectedRecord, onComplete, edit, shops = [], manufacturers = [] }) {
   const toast = useRef(null);

   const [products, setProducts] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [submitted, setSubmitted] = useState(false);
   const [remarks, setRemarks] = useState(null);
   const [selectedManufacturer, setSelectedManufacturer] = useState(null);
   const [selectedShopId, setSelectedShopId] = useState(null);
   const [selectedProduct, setSelectedProduct] = useState(null);
   const [saleUnits, setSaleUnits] = useState([]);
   const [selectedSaleUnit, setSelectedSaleUnit] = useState(null);
   const [selectedSaleUnits, setSelectedSaleUnits] = useState([]);
   const [checked, setChecked] = useState(false);
   const [customName, setCustomName] = useState("");

   const [salesPrice, setSalesPrice] = useState(null);
   const dialogMessage = useRef();

   const pupulateForm = async () => {
      clearForm();

      if (selectedRecord !== null && selectedRecord !== undefined) {
         setIsLoading(true);
         setSalesPrice(selectedRecord.salesPrice);
         setSelectedShopId(shops.find((obj) => obj.id === selectedRecord.shopId));
         setRemarks(selectedRecord.remarks);
         setCustomName(selectedRecord?.customName);

         await fetchProductDetails(selectedRecord?.productId);
      }
   };

   const clearForm = () => {
      setSelectedProduct(null);
      setSelectedSaleUnit(null);
      setSalesPrice(null);
      setRemarks(null);
      setSelectedShopId(null);
      setSelectedManufacturer(null);
      setSelectedSaleUnits([]);
      setChecked(false);
      setSaleUnits([]);
      setSelectedSaleUnit(null);
   };

   const fetchProducts = async (manufacturerId) => {
      let searchParameters = { offset: 0, limit: 0 };

      if (manufacturerId) {
         searchParameters.manufacturerId = manufacturerId;
      }
      new BaseApiService("/products")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setProducts(response.records);
         })
         .catch((error) => {});
   };

   const fetchProductDetails = async (id) => {
      await new BaseApiService(`/products/${id}`)
         .getRequestWithJsonResponse()
         .then(async (response) => {
            const { multipleSaleUnits, manufacturerName, manufacturerId } = response;
            setSelectedProduct(response);
            setSelectedManufacturer({ name: manufacturerName, id: manufacturerId });

            if (multipleSaleUnits?.length > 1) {
               let defaultUnit = multipleSaleUnits.find((unit) => unit.saleUnitName === selectedRecord?.saleUnitName); //looking for the default sale unit
               setSelectedSaleUnit(defaultUnit);
               setSaleUnits(multipleSaleUnits);
               setChecked(true);

               if (selectedRecord.multipleSaleUnits) {
                  setSelectedSaleUnits(
                     selectedRecord?.multipleSaleUnits?.filter((i) => {
                        return multipleSaleUnits.find((unit) => unit.id === i?.productSaleUnitId)?.saleUnitName !== selectedRecord?.saleUnitName;
                     })
                  );
               }
            } else {
               let unit = {
                  saleUnitName: selectedRecord?.saleUnitName,
                  saleUnitId: selectedRecord?.saleUnitId
               };

               setSelectedSaleUnit(unit);
               setSaleUnits([{ ...unit }]);
            }
         })
         .catch((error) => {
            showErrorMessage(dialogMessage, "Unexpected Error, try again.");
         });

      setIsLoading(false);
   };

   const handleUnitPriceChange = (index, value) => {
      const updatedLineItems = [...selectedSaleUnits];
      updatedLineItems[index].unitPrice = value;
      setSelectedSaleUnits([...updatedLineItems]);
   };

   const onSaleUnitSelect = (unit) => {
      const { saleUnitName, id } = unit;

      let itemUnit = {
         id: 0,
         productSaleUnitId: id,
         unitPrice: "",
         saleUnitName
      };

      const isSelected = selectedSaleUnits?.find((item) => item.productSaleUnitId === id);

      if (isSelected) {
         const newList = selectedSaleUnits.filter((item) => item.productSaleUnitId !== id);

         setSelectedSaleUnits([...newList]);
      } else {
         setSelectedSaleUnits((prevSaleUnits) => [...prevSaleUnits, itemUnit]);
      }
   };

   const saveProduct = () => {
      setSubmitted(true);
      setIsLoading(true);
      const apiUrl = "/shop-products/";

      const payload = {
         id: selectedRecord?.id || 0,
         manufacturerId: selectedManufacturer?.id,
         shopId: selectedShopId?.id,
         productId: selectedProduct?.id,
         saleUnitId: selectedSaleUnit?.saleUnitId, //2205
         salesPrice: salesPrice,
         remarks: remarks || "",
         hasMultipleSaleUnits: checked && selectedSaleUnits.length > 0,
         multipleSaleUnits: selectedSaleUnits
      };

      // to check if every field is filled
      if (hasNull(payload) === false) {
         payload.customName = customName;
         new BaseApiService(apiUrl)
            .saveRequestWithJsonResponse(payload, false)
            .then((response) => {
               clearForm();
               setIsLoading(false);
               hideDialog();
               onComplete();
               setSubmitted(false);
               setIsLoading(false);
            })
            .catch((error) => {
               showErrorMessage(dialogMessage, error.message);
               setIsLoading(false);
            });
      } else {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      pupulateForm();
   }, [selectedRecord]);

   const productDialogFooter = (
      <>
         <Button
            loading={isLoading}
            label="Cancel"
            icon="pi pi-times"
            className=" outline-btn"
            onClick={() => {
               hideDialog();
               setSubmitted(false);
               setIsLoading(false);
            }}
         />
         <Button loading={isLoading} label="Save" icon="pi pi-check" className=" primary-btn" onClick={saveProduct} />
      </>
   );

   const onManufacturerChange = (e) => {
      setSelectedManufacturer(e.target.value);
      fetchProducts(e.target.value.id);
   };

   const onShopChange = (e) => {
      setSelectedShopId(e.target.value);
   };

   const onProductChange = (e) => {
      let { multipleSaleUnits } = e.target.value;
      setSelectedProduct(e.target.value);

      setSelectedSaleUnit(multipleSaleUnits?.find((item) => item.saleUnitId === 2205));
      if (multipleSaleUnits) {
         setSaleUnits(multipleSaleUnits);
      } else {
         setSaleUnits([]);
         setSelectedSaleUnits([]);
      }
   };

   return (
      <Dialog
         visible={productDialog}
         style={{ width: "700px" }}
         header="List product"
         modal
         className="p-fluid"
         footer={productDialogFooter}
         onHide={() => {
            hideDialog();
            setSubmitted(false);
            clearForm();
         }}
      >
         <Messages ref={dialogMessage} style={{ width: "100%" }} />
         <div className="formgrid grid">
            <div className="field col-12" style={{ marginTop: "10px" }}>
               <label htmlFor="shop">Shop</label>
               <Dropdown
                  id="shop"
                  value={selectedShopId}
                  options={shops}
                  onChange={onShopChange}
                  optionLabel={"name"}
                  filter
                  showClear
                  filterBy="name"
                  placeholder="Select Shop"
                  className={classNames({
                     "p-invalid": submitted === true && !selectedShopId
                  })}
               />
            </div>

            <div className="field col-12">
               <label htmlFor="manufacturer">Manufacturer</label>
               <Dropdown
                  id="manufacturer"
                  value={selectedManufacturer}
                  options={edit ? [{ ...selectedManufacturer }] : manufacturers}
                  onChange={onManufacturerChange}
                  optionLabel={"name"}
                  filter
                  filterBy="name"
                  placeholder="Select Manufacturer"
                  className={classNames({
                     "p-invalid": submitted === true && !selectedManufacturer
                  })}
               />
            </div>

            <div className="field col-12 md:col-6 lg:col-6">
               <label htmlFor="product">Product</label>
               <Dropdown
                  // disabled={!selectedManufacturer}
                  id="product"
                  value={selectedProduct}
                  options={edit ? [{ ...selectedProduct }] : products}
                  optionLabel="displayName"
                  onChange={onProductChange}
                  required
                  filter
                  filterBy="name"
                  placeholder="Select a product"
                  className={classNames({
                     "p-invalid": submitted === true && !selectedProduct
                  })}
               />
               {submitted === true && !selectedProduct && <small className="p-error">Product is required.</small>}
            </div>

            <div className="field col-12 md:col-6 lg:col-6">
               <label htmlFor="product">Custom name</label>
               <InputText value={customName} onChange={(e) => setCustomName(e.target.value)} />
            </div>

            <div className="field col-12 md:col-6 lg:col-6">
               <label htmlFor="saleUnit">Sale Unit</label>

               <Dropdown
                  id="saleUnit"
                  value={selectedSaleUnit}
                  options={saleUnits}
                  placeholder="Select a sale unit"
                  onChange={(e) => setSelectedSaleUnit(e.target.value)}
                  filter
                  filterBy="value"
                  optionLabel={(item) => item?.saleUnitName || item?.productSaleUnitName}
                  className={classNames({
                     "p-invalid": submitted === true && !selectedSaleUnit
                  })}
               />
               {submitted === true && !selectedSaleUnit && <small className="p-error">Sale unit is required.</small>}
            </div>

            <div className="field col-12 md:col-6 lg:col-6">
               <label htmlFor="salesPrice">Sales Price {selectedRecord?.currency || ""}</label>
               <InputNumber
                  id="salesPrice"
                  value={salesPrice}
                  onValueChange={(e) => setSalesPrice(e.target.value)}
                  required
                  className={classNames({
                     "p-invalid": submitted === true && !salesPrice
                  })}
                  inputClassName="text-right"
               />
               {(submitted === true) === true && !salesPrice && <small className="p-error">Sales price is required.</small>}
            </div>

            {saleUnits.length > 1 && (
               <div className="field col-12">
                  <div className="field">
                     <label>Sale Units</label>

                     <div className="flex flex-wrap gap-3 ">
                        {saleUnits
                           ?.filter(
                              (item) =>
                                 item?.productSaleUnitName !== selectedSaleUnit?.productSaleUnitName ||
                                 item?.saleUnitName !== selectedSaleUnit?.saleUnitName
                           )
                           ?.map((item) => {
                              const isSelected = selectedSaleUnits?.find(
                                 (unit) => item?.saleUnitName === unit?.productSaleUnitName || item?.saleUnitName === unit?.saleUnitName
                              );
                              return (
                                 <Button
                                    className={classNames({
                                       "outline-btn p-button-rounded": !isSelected,
                                       "p-button-rounded": isSelected
                                    })}
                                    key={item.saleUnitName}
                                    onClick={() => onSaleUnitSelect(item)}
                                    label={item?.saleUnitName}
                                    style={{ width: "fit-content" }}
                                 />
                              );
                           })}
                     </div>
                  </div>

                  {selectedSaleUnits.length > 0 && (
                     <div className="formgrid grid">
                        <div className="field col-6">
                           <label htmlFor="UnitPrice">Unit</label>
                        </div>
                        <div className="field col-6">
                           <label htmlFor="expiryDate">Sales price {selectedRecord?.currency && `(${selectedRecord?.currency})`}</label>
                        </div>
                     </div>
                  )}
                  {selectedSaleUnits?.map((item, index) => {
                     return (
                        <div className="formgrid grid">
                           <div className="field col-6">
                              <InputText readOnly value={item.saleUnitName || item.productSaleUnitName} />
                           </div>

                           <div className="field col-6">
                              <InputNumber
                                 value={item.unitPrice}
                                 onValueChange={(e) => handleUnitPriceChange(index, e.value)}
                                 inputClassName="text-right"
                              />
                           </div>
                        </div>
                     );
                  })}
               </div>
            )}

            <div className="field col-12">
               <label htmlFor="remarks">Remarks</label>
               <InputTextarea autoResize style={{ minHeight: 100 }} id="remarks" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
            </div>
         </div>
      </Dialog>
   );
}
