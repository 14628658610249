import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { Messages } from "primereact/messages";
import React, { useEffect, useRef, useState } from "react";
import { convertToServerDate, formatNumberWithCommas, toReadableDate, toReadableDateTime, toReadableTime } from "../../utils/Utils";
import { BaseApiService } from "../../utils/BaseApiService";
import { showErrorMessage, showSuccessMessage } from "../../constants/ErrorMessages";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import FormFooter from "components/FormFooter";
import { Dropdown } from "primereact/dropdown";

function EditSaleDialog({ selectedSale, editSale, setEditSale, onFinish, toast }) {
   const dialogMessage = useRef(null);

   const [isLoading, setIsLoading] = useState(false);
   const [soldOnDate, setSoldOnDate] = useState(null);
   const [clients, setClients] = useState([]);
   const [selectedClient, setSelectedClient] = useState(null);
   const [amountPaid, setAmountPaid] = useState(null);

   const saveSale = () => {
      const payload = {
         id: selectedSale?.id,
         amountPaid: amountPaid,
         soldOnDate: convertToServerDate(soldOnDate),
         ...(selectedClient && { clientId: selectedClient.id, clientName: selectedClient.name, clientPhone: selectedClient.phone })
      };

      let apiUrl = `/shop-sales/${selectedSale?.id}`;

      setIsLoading(true);
      new BaseApiService(apiUrl)
         .saveRequestWithJsonResponse(payload, true)
         .then((response) => {
            onFinish();
            showSuccessMessage(toast, "Sale updated successfully");
            setSelectedClient(null);
            setIsLoading(false);
            setEditSale(false);
         })
         .catch((error) => {
            showErrorMessage(dialogMessage, error.message);
            setIsLoading(false);
         });
   };

   const fetchClients = () => {
         setSelectedClient(null);
         setIsLoading(true);
         const searchParameters = { limit: 0, offset: 0, shopId: selectedSale?.shopId };

         new BaseApiService("/clients-controller")
            .getRequestWithJsonResponse(searchParameters)
            .then((response) => {
               setClients(response.records);
               setSelectedClient(response?.records?.find((i) => i?.id === selectedSale?.clientId));
               setIsLoading(false);
            })
            .catch((error) => {
               setIsLoading(false);
            });
   };

   const unitCostBodyTemplate = (rowData) => {
      return <>{formatNumberWithCommas(rowData?.unitCost)}</>;
   };

   const totalCostBodyTemplate = (rowData) => {
      return <>{formatNumberWithCommas(rowData?.totalCost)}</>;
   };

   const nameBodyTemplate = (rowData) => {
      const { saleUnitName, shopProductName } = rowData;
      const unitName = saleUnitName ? " - " + saleUnitName : "";

      return <span className="wrap">{shopProductName + unitName}</span>;
   };

   useEffect(() => {
      if (selectedSale) {
         setSoldOnDate(new Date(selectedSale?.soldOnDate));
         setAmountPaid(selectedSale?.amountPaid);
         fetchClients();
         
      }
   }, [selectedSale]);

   return (
      <Dialog
         visible={editSale}
         onHide={() => setEditSale(false)}
         style={{ width: "700px" }}
         header={`Edit sale record `}
         className="p-fluid"
         footer={() => (
            <FormFooter
               onClickLeft={() => {
                  setEditSale(false);
                  setIsLoading(false);
               }}
               onClickRight={saveSale}
               isLoading={isLoading}
            />
         )}
      >
         <Messages ref={dialogMessage} style={{ width: "100%" }} />

         <div className="mt-2 flex justify-content-between">
            <div>
               <span className="font-normal">{selectedSale?.shopName}</span>
               <br />
               <span className="text-sm">
                  <b>SN:</b> {selectedSale?.serialNumber}
               </span>
            </div>

            <div className="flex gap-2">
               <b>Sold on: </b>
               <span className="text-right">
                  {toReadableDate(selectedSale?.soldOnDate)}
                  <br /> {toReadableTime(selectedSale?.dateCreated)}
               </span>
            </div>
         </div>

         <DataTable
            value={selectedSale?.lineItems}
            dataKey="id"
            className="mt-5 "
            emptyMessage="No items for sale found."
            size="small"
            resizableColumns
            tableClassName="col-12"
         >
            <Column field="shopProductName" header="Item" body={nameBodyTemplate} className="text-left" style={{ width: "50%" }} />
            <Column field="quantity" header="Qty" className="text-center" />
            <Column field="unitCost" body={unitCostBodyTemplate} header="Cost" className="text-right" />
            <Column field="totalCost" body={totalCostBodyTemplate} header="Amount" className="text-right" />
         </DataTable>

         <div className="px-2">
            <div className="flex justify-content-between mt-2">
               <span className="font-semibold">Total</span>
               <span className="font-normal">
                  {selectedSale?.currency} <span className="text-lg font-semibold">{formatNumberWithCommas(selectedSale?.totalCost)}</span>
               </span>
            </div>
         </div>
         <div className="formgrid grid -mb-3 mt-3">
            <div className="field col-12 md:col-6 lg:col-6">
               <label>Client</label>
               <Dropdown
                  options={clients}
                  filter
                  value={selectedClient}
                  onChange={(e) => setSelectedClient(e.value)}
                  optionLabel={(e) => <label className=" flex justify-content-between">{e?.fullName} <span>{e?.phoneNumber}</span></label>}
                  // optionValue="id"
               />
            </div>
            <div className="field col-11 md:col-6 lg:col-3">
               <label>Amount paid</label>
               <InputNumber
                  inputClassName="text-right"
                  value={amountPaid}
                  onChange={(e) => setAmountPaid(e.value)}
                  // className="prevent-events"
               />
            </div>

            <div className="field col-12 md:col-6 lg:col-3">
               <label>Sold on</label>
               <Calendar
                  inputClassName="text-center"
                  dateFormat="dd-M-yy"
                  value={soldOnDate}
                  onChange={(e) => {
                     setSoldOnDate(e.value);
                  }}
               />
            </div>
         </div>

         <div className="px-0">
            <div className="flex justify-content-between mt-4">
               <span className="font-semibold ">Served by:</span>
               <span className=" font-normal">{selectedSale?.createdByFullName}</span>
            </div>

            <div className="flex justify-content-between mt-2">
               <span className="font-semibold"> Edited by:</span>
               <span className="font-normal">{selectedSale?.changedByFullName}</span>
            </div>

            <div className="flex justify-content-between mt-2">
               <span className="font-semibold">Last changed:</span>
               <span className=" font-normal">{toReadableDateTime(selectedSale?.dateChanged)}</span>
            </div>
         </div>
      </Dialog>
   );
}

export default EditSaleDialog;
