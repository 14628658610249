import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { BaseApiService } from "utils/BaseApiService";
import { ReactComponent as Edit } from "../assets/icons/edit-3-svgrepo-com.svg";
import { TabPanel, TabView } from "primereact/tabview";
import { toReadableDate, toReadableDateTime } from "utils/Utils";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { template2 } from "components/PaginatorTemplate";
import { Dialog } from "primereact/dialog";
import ShopAttendantForm from "components/dialogs/ShopAttendantForm";
import { showSuccessMessage } from "constants/ErrorMessages";
import EditAttendantRoles from "components/dialogs/EditAttendantRoles";
import { PrimeIcons } from "primereact/api";

const ShopProfile = () => {
   let emptyShopAttendant = {
      id: null,
      firstName: null,
      lastName: null,
      preferredUsername: null,
      otherName: null,
      passportPhoto: null,
      emailAddress: null,
      nationalID: null,
      nationalIDNumber: null,
      primaryPhoneNumber: null,
      alternativePhoneNumber: null,
      postalAddress: null,
      lc1Letter: null,
      dateOfBirth: null,
      genderId: 1,
      genderName: null,
      shopId: null,
      shopName: null
   };

   const { state } = useLocation();
   const toast = useRef(null);
   const [selectedShopAttendant, setSelectedShopAttendant] = useState(null);
   const [shopAttendants, setShopAttendants] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const [shopAttendantDialog, setShopAttendantDialog] = useState(false);
   const [roleDialog, setRoleDialog] = useState(false);
   const [shopAttendant, setShopAttendant] = useState(emptyShopAttendant);
   const [deleteShopAttendantDialog, setDeleteShopAttendantDialog] = useState(false);
   const [genders, setGenders] = useState(null);
   const [shops, setShops] = useState([state]);
   const dt = useRef(null);

   // const fetchShopDetails = () => {
   //    new BaseApiService(`/shops/${state?.id}`)
   //       .getRequestWithJsonResponse()
   //       .then((response) => {
   //          console.log(response);
   //       })
   //       .catch((error) => {
   //          console.log(error);
   //       });
   // };

   const fetchShopUsers = () => {
      new BaseApiService(`/shops/${state?.id}/user-accounts`)
         .getRequestWithJsonResponse()
         .then((response) => {
            const mod = response.records.map((item) => {
               return {
                  ...item,
                  shopId: state?.id
               };
            });
            setShopAttendants(mod);
         })
         .catch((error) => {
            console.log(error);
         });
   };

   const deleteShopAttendant = () => {
      new BaseApiService("/shops/attendants/" + shopAttendant.id)
         .deleteRequestWithJsonResponse()
         .then((response) => {
            let _shopAttendants = shopAttendants.filter((val) => val.id !== shopAttendant.id);
            setShopAttendants(_shopAttendants);
            setDeleteShopAttendantDialog(false);
            setShopAttendant(emptyShopAttendant);
            toast.current.show({
               severity: "success",
               summary: "Successful",
               detail: "Shop attendant Deleted",
               life: 3000
            });
         })
         .catch((error) => {});
   };

   const fetchGenders = async () => {
      new BaseApiService("/lookups/genders")
         .getRequestWithJsonResponse()
         .then(async (response) => {
            setGenders(response?.records);
         })
         .catch((error) => {});
   };

   useEffect(() => {
      // fetchShopDetails();
      fetchShopUsers();
      fetchGenders();
   }, []);

   const hideShopAttendantDialog = () => {
      setDeleteShopAttendantDialog(false);
   };

   const confirmDeleteShopAttendant = (shopAttendant) => {
      setShopAttendant(shopAttendant);
      setDeleteShopAttendantDialog(true);
   };

   const copyAccount = () => {
      navigator.clipboard.writeText('10001202245677');

      toast.current.show({
         severity: "info",
         summary: "Info",
         detail: "Account number copied",
         life: 3000
      });
   }

   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex md:flex-row md:align-items-center">
            <Button
               icon="pi pi-pencil"
               className="p-button-rounded p-button-text p-button-success mr-2"
               onClick={() => {
                  setSelectedShopAttendant(rowData);
                  setShopAttendantDialog(true);
               }}
            />
            <Button
               icon="pi pi-shield"
               className="p-button-rounded p-button-text p-button-success mr-2"
               onClick={() => {
                  setSelectedShopAttendant(rowData);
                  setRoleDialog(true);
               }}
            />

            <Button
               icon="pi pi-trash"
               className="p-button-rounded p-button-text p-button-danger mr-2"
               onClick={() => confirmDeleteShopAttendant(rowData)}
            />
         </div>
      );
   };

   const deleteShopAttendantDialogFooter = (
      <>
         <Button label="No" icon="pi pi-times" className="outline-btn" onClick={hideShopAttendantDialog} />
         <Button label="Yes" icon="pi pi-check" className="primary-btn" onClick={deleteShopAttendant} />
      </>
   );

   const performanceTab = (
      <TabPanel header="Performance Summary">
         <div className="field">
            <div className="flex mb-4 flex-wrapper">
               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     Total Capital Invested:
                     <br />
                     <span className="font-bold">{state?.totalCapitalInvested}</span>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     Total Capital Withdrawn: <br />
                     <span className="font-bold"> {state?.totalCapitalWithdrawn} </span>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <>Total Sales Value:</> <br />
                     <span className="font-bold"> {state?.totalSalesValue} </span>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <>Total Stock Value:</> <br />
                     <span className="font-bold"> {state?.totalStockValue} </span>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <>Total Expenses:</> <br />
                     <span className="font-bold"> {state?.totalExpenses} </span>
                  </label>
               </div>
            </div>
            <div className="flex mb-4 flex-wrapper">
               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <>Total Capital Invested This Year:</> <br />
                     <span className="font-bold"> {state?.totalCapitalInvestedThisYear} </span>
                  </label>
               </div>
               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="">Total Capital Withdrawn This Year:</span>
                     <br /> <b>{state?.totalCapitalWithdrawnThisYear}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="">Total Sales Value This Year:</span>
                     <br /> <b>{state?.totalSalesValueThisYear}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="">Total Stock Value This Year:</span>
                     <br /> <b>{state?.totalStockValueThisYear}</b>
                  </label>
               </div>

               <div className="col-12 md:col-6 lg:col-2">
                  <label className="mt-3">
                     <span className="">Total Expenses This Year:</span>
                     <br /> <b>{state?.totalExpensesThisYear}</b>
                  </label>
               </div>
            </div>
         </div>
      </TabPanel>
   );

   const usersTab = (
      <TabPanel header="Users">
         <DataTable
            ref={dt}
            value={shopAttendants}
            dataKey="id"
            paginator
            rows={10}
            loading={isLoading}
            className="small-td"
            paginatorTemplate={template2}
            emptyMessage="No shop attendants found."
            size="small"
            resizableColumns
         >
            <Column field="fullName" header="Name" body={(d) => d?.firstName + " " + d?.lastName} />
            <Column field="username" header="Username" />
            <Column field="phoneNumber" header="Phone" />
            <Column field="createdByFullName" header="Created by" />
            <Column field="dateCreated" header="Created on" body={(d) => toReadableDateTime(d?.dateCreated)} />
            <Column body={actionBodyTemplate} header="Actions" />
         </DataTable>
      </TabPanel>
   );

   const TabViw = (
      <TabView className="h-10rem">
         <TabPanel header="Profile">
            <div className="field">
               <div className="flex mb-4 flex-wrapper">
                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        Owner:
                        <br />
                        <span className="font-bold">{state?.ownerName}</span>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        Physical Address: <br />
                        <span className="font-bold"> {state?.physicalLocation} </span>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <>Registration Number:</> <br />
                        <span className="font-bold"> {state?.registrationNumber} </span>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <>Support Credit Sales:</> <br />
                        <span className="font-bold"> {state?.supportsCreditSales} </span>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <>Category:</> <br />
                        <span className="font-bold"> {state?.categoryName} </span>
                     </label>
                  </div>
               </div>
               <div className="flex mb-4 flex-wrapper">
                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Longitude</span>
                        <br /> <b>{state?.locationLongitude}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Latitude:</span>
                        <br /> <b>{state?.locationLatitude}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2">
                     <label className="mt-3">
                        <span className="font-medium">Serial number:</span>
                        <br />
                        <b>{state?.serialNumber}</b>
                     </label>
                  </div>

                  <div className="col-12 md:col-6 lg:col-2 mr-3">
                     <label className="mt-3">
                        <span className="font-medium">Created on: </span>
                        <br /> <b>{toReadableDate(state?.dateCreated)}</b>
                     </label>
                  </div>
               </div>
            </div>
         </TabPanel>
         {performanceTab}
         {usersTab}
      </TabView>
   );

   return (
      <div className="grid crud-demo h-full">
         <div className="col-12">
            <div className="card border-round-lg">
               <Toast ref={toast} />

               <div className="col-12">
                  <div className="grid">
                     <div className="col-12 md:col-12 lg:col-1">
                        <div className="flex align-items-center justify-content-center">
                           <i className="pi pi-user shop-image align-items-center justify-content-center flex"></i>
                        </div>
                     </div>

                     <div className="col-12 md:col-12 lg:col-10 flex align-items-center">
                        <div className="field w-full">
                           <div className="">
                              <div className="flex justify-content-between">
                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       Shop Name:
                                       <br />
                                       <span className="font-bold">{state?.name}</span>
                                    </label>
                                 </div>
                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       Owner:
                                       <br />
                                       <span className="font-bold">{state?.ownerName}</span>
                                    </label>
                                 </div>

                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       Physical Address: <br />
                                       <span className="font-bold"> {state?.physicalLocation} </span>
                                    </label>
                                 </div>

                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       <>Registration Number:</> <br />
                                       <span className="font-bold"> {state?.registrationNumber} </span>
                                    </label>
                                 </div>

                                 <div className="col-12 md:col-6 lg:col-2">
                                    <label className="mt-3">
                                       <>Category:</> <br />
                                       <span className="font-bold"> {state?.categoryName} </span>
                                    </label>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className=" edit-icon flex flex-column">
                     <i className="pi pi-pencil bg-black-alpha-10 p-2 border-round" aria-label="Refresh" title="Edit" />
                        <i className="pi pi-refresh mt-1 bg-black-alpha-10 p-2 border-round" title="Refresh" />
                     </div>
                  </div>
               </div>
            </div>
            <div className="flex">
               <div className="card border-round-lg heighty w-full">{TabViw}</div>
               <div className="card border-round-lg heighty w-5 ml-3 flex flex-column">
                  <label className=" font-bold bolder">Subscription Information</label>
                  <div className="flex flex-column mt-4">
                     <label className=" text-black-alpha-90 font-medium ml-2">Plan</label>
                     <div className="w-full border-round-xl border-1 border-black-alpha-10 mt-1 relative p-1">
                        <label className="flex justify-content-between p-3 text-black-alpha-30 border-bottom-1 border-black-alpha-20">Plan <span className=" text-black-alpha-90">Basic Plan UG</span></label>
                        <label className="flex justify-content-between p-3 text-black-alpha-30 border-bottom-1 border-black-alpha-20">Amount<span className=" text-black-alpha-90"><span className="text-sm">UGX </span>20,000</span></label>
                        <label className="flex justify-content-between p-3 text-black-alpha-30 border-bottom-1 border-black-alpha-20">Expiry <span className=" text-black-alpha-90">2025-02-01</span></label>
                        <label className="flex justify-content-between p-3 text-black-alpha-30 ">Created by <span className=" text-black-alpha-90">Paul Mboya</span></label>
                     </div>
                  </div>
                  <div className="flex flex-column mt-3">
                     <label className=" text-black-alpha-90 font-medium ml-2">Payment</label>
                     <div className="w-full border-round-xl border-1 border-black-alpha-10 mt-1 relative p-1">
                        <label className="flex justify-content-between p-3 text-black-alpha-30 border-bottom-1 border-black-alpha-20">Account name <span className=" text-black-alpha-90">FoundDocument Agency</span></label>
                        <label className="flex justify-content-between p-3 text-black-alpha-30 border-bottom-1 border-black-alpha-20">Bank name<span className=" text-black-alpha-90">Equity Bank Uganda</span></label>
                        <label className="flex justify-content-between p-3 text-black-alpha-30">Account number <span className=" text-black-alpha-90">10001202245677</span></label>
                     </div>
                     <Button label="Copy Account Number" className=" h-3rem bg-black-alpha-90 text-yellow-700 border-none mt-2" icon="pi pi-clone" iconPos="right" onClick={copyAccount} />
                  </div>
               </div>
            </div>
            <Dialog
               visible={deleteShopAttendantDialog}
               style={{ width: "450px" }}
               header="Confirm"
               modal
               footer={deleteShopAttendantDialogFooter}
               onHide={hideShopAttendantDialog}
            >
               <div className="flex align-items-center justify-content-center">
                  <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                  {shopAttendant && (
                     <span>
                        Are you sure you want to delete <b>{shopAttendant.firstName}</b>?
                     </span>
                  )}
               </div>
            </Dialog>
            <ShopAttendantForm
               shops={shops}
               selectedShopAttendant={selectedShopAttendant}
               setSelectedShopAttendant={setSelectedShopAttendant}
               visible={shopAttendantDialog}
               setVisible={setShopAttendantDialog}
               genders={genders}
               onFinish={() => {
                  showSuccessMessage(toast, "Shop User Details saved");
                  fetchShopUsers();
               }}
            />
            <EditAttendantRoles
               selectedRecord={selectedShopAttendant}
               roleDialog={roleDialog}
               setRoleDialog={setRoleDialog}
               onComplete={() => {
                  fetchShopUsers();
                  showSuccessMessage(toast, "Shop User Details saved");
               }}
            />
         </div>
      </div>
   );
};

export default ShopProfile;
