import { BASE_URL_ENDPOINT_PATH } from "../utils/EndpointsUtils";

export const STORAGE_URL = "https://storage.bunnycdn.com/materialdesignnative";
export const ACCESS_KEY = "ba8dd74c-5844-4d83-8523b4a5f50d-a128-4190";
export const BASE_URL = BASE_URL_ENDPOINT_PATH;
export const REMOTE_BASE_URL = BASE_URL;
export const REMOTE_WEB_URL = BASE_URL;
export const WEB_URL = "https://backend.duqact.com/";
export const MAXIMUM_RECORDS_PER_PAGE = 10;

export const PAGINATION_SIZE_OPTIONS = [
   { label: 10, value: 10 },
   { label: 20, value: 20 },
   { label: 50, value: 50 }
];

export const productionStatuses = [
   { name: "Active", value: 1 },
   { name: "Paused", value: 2 },
   { name: "Stopped", value: 3 }
];

export const paymentMethods = [
   { name: "Cash", id: 0 },
   { name: "Credit", id: 1 }
];
export const genderOptions = [
   { name: "Male", id: 0 },
   { name: "Female", id: 1 },
   { name: "Rather not say", id: 2 }
];

export const months = [
   { id: 1, name: "January" },
   { id: 2, name: "February" },
   { id: 3, name: "March" },
   { id: 4, name: "April" },
   { id: 5, name: "May" },
   { id: 6, name: "June" },
   { id: 7, name: "July" },
   { id: 8, name: "August" },
   { id: 9, name: "September" },
   { id: 10, name: "October" },
   { id: 11, name: "November" },
   { id: 12, name: "December" }
];

export const roleTypes = [{ value: "SystemAdmins" }, { value: "ShopManagers" }];

export const subscriptionPaymentMethods = [
   { id: 1, name: "CASH" },
   { id: 2, name: "MPESA" },
   { id: 3, name: "AIRTEL_MONEY" },
   { id: 4, name: "MTN_MOMO" },
   { id: 5, name: "SYSTEM" },
   { id: 6, name: "BANK" }
];

export const damageTypes = [
   { type: "EXPIRY", name: "Expiry" },
   { type: "SALE_TIME", name: "Sale Time" },
   { type: "FACTORY_ERROR", name: "Factory Error" },
   { type: "DELIVERY_ERROR", name: "Deliver Error" },
   { type: "OTHER", name: "Other" }
];

export const stockStatuses = [
   { id: "IN_STOCK", name: "In Stock" },
   { id: "RUNNING_OUT", name: "Running Out" },
   { id: "OUT_OF_STOCK", name: "Out Of Stock" },
   { id: "ABANDONED", name: "Abandoned" }
];

export const paymentModes = [
   { type: "CASH", name: "Cash" },
   { type: "CREDIT", name: "Credit" },
   { type: "MOMO", name: "Mobile money" }
];

export const salesRecordStatuses = [
   { type: "CONFIRMED", name: "Confirmed" },
   { type: "DRAFT", name: "Draft" },
   { type: "CANCELLED", name: "Cancelled" }
];

export const CLIENT_DEBT_RANGES = [
   { name: "Non debtors", min: 0, max: 0},
   { name: "1 to 10,000 in debt", min: 1, max: 10000 },
   { name: "10,000 to 100,000 in debt", min: 10000, max: 100000 },
   { name: "100,000 to 1,000,000 in debt", min: 100000, max: 1000000 },
   { name: "1,000,000 and above in debt", min: 1000000 }
];
