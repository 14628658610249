import { Dialog } from "primereact/dialog";
import React, { useState, useEffect, useRef } from "react";
import ChipButton from "../ChipButton";
import { paymentMethods } from "../../constants/Constants";
import { DataTable } from "primereact/datatable";
import { convertToServerDate, formatNumberWithCommas } from "../../utils/Utils";
import { Column } from "primereact/column";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { BaseApiService } from "../../utils/BaseApiService";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { Messages } from "primereact/messages";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import PhoneInput from "react-phone-input-2";
import { showErrorMessage } from "../../constants/ErrorMessages";
import { InputSwitch } from "primereact/inputswitch";
import SplitButton from "components/SplitButton";
import { RecieptPrintDialog } from "pages/RecieptPrintout";

function PaymentModeForm({ showPaymentDialog, hidePaymentDialog, cart, selectedShop, toast, refreshTable, selectedCurrency = "" }) {
   const [paymentMode, setPaymentMode] = useState(null);
   const [soldOnDate, setSoldOnDate] = useState(new Date());
   const [submitted, setSubmitted] = useState(false);
   const [isLoading, setLoading] = useState(false);
   const [amountPaid, setAmountPaid] = useState(0);
   const [clients, setClients] = useState([]);
   const [selectedClient, setSelectedClient] = useState(null);
   const [clientName, setClientName] = useState("");
   const [clientNumber, setClientNumber] = useState("");
   const [existingClient, setExistingClient] = useState(false);
   const [sale, setSale] = useState(null);
   const [showPrintDialog, setShowPrintDialog] = useState(false);

   const isShopAttendant = UserSessionUtils.getShopAttendant();
   const attendantShopId = UserSessionUtils.getShopId();
   const dialogMessage = useRef();

   const { captureClientDetailsOnAllSales, supportsCreditSales } = selectedShop ?? {};

   const onClick = (payment) => {
      onToggle();
      setPaymentMode(payment);
   };

   useEffect(() => {
      setPaymentMode(paymentMethods[0]);
   }, [showPaymentDialog]);

   const onToggle = () => {
      setClientName("");
      setClientNumber("");
      setSelectedClient(null);
   };

   const saveSale = async (print) => {
      setSubmitted(true);
      let idToUse = isShopAttendant ? attendantShopId : selectedShop?.id;

      const onCredit = paymentMode?.id === 1;

      const payLoad = {
         id: 0,
         shopId: idToUse,
         amountPaid: onCredit ? amountPaid : cart?.receivedAmount,
         lineItems: cart?.cartItems,
         paymentMode: paymentMode?.id,
         onCredit: onCredit,
         soldOnDate: convertToServerDate(soldOnDate),
         ...(selectedClient && {
            clientPhoneNumber: selectedClient?.phoneNumber,
            clientId: selectedClient?.id,
            clientName: selectedClient?.fullName
         }),
         ...(!onCredit && clientNumber && { clientPhoneNumber: clientNumber }),
         ...(!onCredit && clientName && { clientName: clientName })
      };

      setLoading(true);
      await new BaseApiService("/shop-sales")
         .postRequest(payLoad)
         .then(async (response) => {
            let d = { info: await response.json(), status: response.status };
            if (print) {
               setSale(d.info);       
            }
            return d;
         })
         .then(async (d) => {
            let { info, status } = d;

            let id = info.id;

            if (status === 200) {
               new BaseApiService(`/shop-sales/${id}/confirm`)
                  .postRequest()
                  .then((d) => d.json())
                  .then((d) => {
                     if (d.status === "Success") {
                        hideDialog();
                        toast.current.show({
                           severity: "success",
                           summary: "Success",
                           detail: "Sale saved Successfully",
                           life: 3000
                        });
                        refreshTable();
                        setLoading(false);
                        setSubmitted(false);
                     }
                  })
                  .catch((error) => {
                     showErrorMessage(dialogMessage, error?.message);
                     setSubmitted(false);
                     setLoading(false);
                  });
            } else {
               showErrorMessage(dialogMessage, info?.message);
               setLoading(false);
            }
         })
         .catch((error) => {
            showErrorMessage(dialogMessage, error.message);
            setSubmitted(false);
            setLoading(false);
         })
         .finally(() => 
            setShowPrintDialog(print) 
         );
   };

   const unitCostBodyTemplate = (rowData) => {
      return <>{formatNumberWithCommas(rowData?.unitCost)}</>;
   };

   const totalCostBodyTemplate = (rowData) => {
      return <>{formatNumberWithCommas(rowData?.cost)}</>;
   };

   const fetchClients = () => {
      const searchParameters = {
         limit: 0,
         offset: 0,
         shopId: isShopAttendant ? attendantShopId : selectedShop?.id
      };

      new BaseApiService("/clients-controller")
         .getRequestWithJsonResponse(searchParameters)
         .then((response) => {
            setClients(response.records);
         })
         .catch((error) => {
            console.error(error);
         });
   };

   const hideDialog = () => {
      hidePaymentDialog();
      setPaymentMode(1);
      setSelectedClient(null);
      setSubmitted(false);
      setLoading(false);
      setClientName("");
      setClientNumber(null);
   };

   const productDialogFooter = (
      <div className="flex justify-content-end">
         <Button loading={isLoading} label="Cancel" icon="pi pi-times" className=" outline-btn" onClick={hideDialog} />
         {/* <Button loading={isLoading} label="Save" icon="pi pi-check" className="primary-btn" onClick={saveSale} /> */}
         <SplitButton
            items={[
               {
                  label: "Save",
                  icon: "pi pi-check",
                  key: 0
               },
               {
                  label: "Save and Print",
                  icon: "pi pi-print",
                  key: 1
               }
            ]}
            saveSale={saveSale}
            printAndSave={() => {saveSale(true)}}
         />
      </div>
   );

   useEffect(() => {
      if (selectedShop) {
         fetchClients();
      }
   }, [selectedShop]);

   return (
      <>
         <Dialog
         footer={productDialogFooter}
         header={"Payment mode details"}
         visible={showPaymentDialog}
         className="p-fluid"
         style={{ minWidth: '550px' }}
         

         modal
         onHide={hideDialog}
         >
            <div>
               <Messages ref={dialogMessage} style={{ width: "100%" }} />

               <DataTable value={cart?.cartItems} dataKey="id" className="field " emptyMessage="No items for sale found." size="small" resizableColumns>
                  <Column field="productName" header="Item" className="text-left gap-1" bodyClassName={"text-overflow-ellipsis"} />
                  <Column field="quantity" header="Qty" className="text-center" />
                  <Column field="unitCost" body={unitCostBodyTemplate} header="Cost" className="text-right" />
                  <Column field="totalCost" body={totalCostBodyTemplate} header="Amount" className="text-right" />
               </DataTable>

               <div className="field col">
                  <div className="flex justify-content-between py-2">
                     <span className="font-semibold">Total</span>
                     <span className="text-sm font-normal">
                        {selectedCurrency} <span className="text-lg font-semibold">{formatNumberWithCommas(cart?.totalAmount)}</span>
                     </span>
                  </div>
                  <div className="flex justify-content-between py-2">
                     <span className="font-semibold">Recieved</span>
                     <span className="text-sm font-normal">
                        {selectedCurrency} <span className="text-lg font-semibold">{formatNumberWithCommas(cart?.recievedAmount)}</span>
                     </span>
                  </div>

                  <div className="flex justify-content-between py-2">
                     <span className="font-semibold">Balance</span>
                     <span className="text-sm font-normal">
                        {selectedCurrency} <span className="text-lg font-semibold">{formatNumberWithCommas(cart?.balanceGivenOut)}</span>
                     </span>
                  </div>
               </div>

               <div className="field col">
                  {supportsCreditSales === true && (
                     <>
                        <h6>Payment mode</h6>
                        <div className="flex flex-wrap gap-3 ">
                           {paymentMethods.map((payment) => (
                              <ChipButton
                                 key={payment.id}
                                 label={payment.name}
                                 onClick={() => onClick(payment)}
                                 isSelected={paymentMode?.id === payment.id}
                              />
                           ))}
                        </div>
                     </>
                  )}

                  <div className="formgrid grid ">
                     {paymentMode?.id === 0 && (
                        <>
                           {captureClientDetailsOnAllSales && (
                              <>
                                 <div className="flex gap-3 align-items-center col-12 mt-4">
                                    <label>Existing Client</label>
                                    <InputSwitch
                                       checked={existingClient}
                                       onChange={() => {
                                          onToggle();
                                          setExistingClient(!existingClient);
                                       }}
                                    />
                                 </div>
                                 {!existingClient && (
                                    <>
                                       <div className="col-12 md:col-6 lg:col-6 mt-2">
                                          <label htmlFor="qty">Client name</label>
                                          <InputText value={clientName} style={{ color: "#000" }} onChange={(e) => setClientName(e.target.value)} />
                                       </div>
                                       <div className="col-12 md:col-6 lg:col-6 mt-2">
                                          <label>Phone number</label>
                                          <PhoneInput
                                             inputClass="w-full"
                                             enableSearch
                                             value={clientNumber}
                                             onChange={(e) => setClientNumber(e)}
                                             country={"ug"}
                                             countryCodeEditable={false}
                                             autoFormat
                                          />
                                       </div>
                                    </>
                                 )}

                                 {existingClient && (
                                    <>
                                       <div className="col-12 md:col-6 lg:col-6 mt-2">
                                          <label htmlFor="qty">Client name</label>
                                          <Dropdown
                                             value={selectedClient}
                                             options={clients}
                                             optionLabel={"fullName"}
                                             filter
                                             onChange={(e) => {
                                                setSelectedClient(e.value);
                                             }}
                                             placeholder="Select client"
                                          />{" "}
                                          {submitted && !selectedClient && <small className="p-error">Client name is required.</small>}
                                       </div>
                                       <div className="col-12 md:col-6 lg:col-6 mt-2">
                                          <label>Phone number</label>
                                          <PhoneInput
                                             inputClass="w-full"
                                             enableSearch
                                             value={selectedClient?.phoneNumber}
                                             onChange={(e) => setClientNumber(e)}
                                             country={"ug"}
                                             countryCodeEditable={false}
                                             autoFormat
                                             disabled
                                          />
                                       </div>
                                    </>
                                 )}
                              </>
                           )}
                        </>
                     )}

                     {paymentMode?.id === 1 && supportsCreditSales === true && (
                        <>
                           <div className="col-12 md:col-6 lg:col-6 mt-2">
                              <label htmlFor="qty">Client name</label>
                              <Dropdown
                                 value={selectedClient}
                                 options={clients}
                                 optionLabel={"fullName"}
                                 filter
                                 onChange={(e) => {
                                    setSelectedClient(e.value);
                                 }}
                                 placeholder="Select client"
                              />{" "}
                              {submitted && !selectedClient && <small className="p-error">Client name is required.</small>}
                           </div>
                           <div className="col-12 md:col-6 lg:col-6 mt-2">
                              <label>Phone number</label>
                              <PhoneInput
                                 inputClass="w-full"
                                 enableSearch
                                 value={selectedClient?.phoneNumber}
                                 onChange={(e) => setClientNumber(e)}
                                 country={"ug"}
                                 countryCodeEditable={false}
                                 autoFormat
                                 disabled
                              />
                           </div>
                        </>
                     )}
                  </div>

                  <div className="formgrid grid ">
                     {paymentMode?.id === 1 && (
                        <div className="col-12 md:col-6 lg:col-6 mt-2">
                           <label>Amount paid</label>
                           <InputNumber onFocus={() => setSubmitted(false)} value={amountPaid} onValueChange={(e) => setAmountPaid(e.value)} />
                        </div>
                     )}
                     <div className="col-12 md:col-6 lg:col-6 mt-2">
                        <label htmlFor="expiryDate">Sold on</label>
                        <Calendar value={soldOnDate} onChange={(e) => setSoldOnDate(e.value)} dateFormat="dd-M-yy" />
                     </div>
                  </div>
               </div>
            </div>
         </Dialog>
         <RecieptPrintDialog saleData={sale} toggleFn={setShowPrintDialog} visible={showPrintDialog} reloadFn={setShowPrintDialog} />
      </>
   );
}

export default PaymentModeForm;
