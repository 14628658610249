import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { BaseApiService } from "../../utils/BaseApiService";
import { formatNumberWithCommas, toReadableDateTime } from "../../utils/Utils";
import { UserSessionUtils } from "../../utils/UserSessionUtils";
import { MAXIMUM_RECORDS_PER_PAGE } from "../../constants/Constants";
import { Paginator } from "primereact/paginator";
import { useLocation } from "react-router-dom";
import { template2 } from "components/PaginatorTemplate";
import FilterComponent from "components/FilterComponent";
import { Dropdown } from "primereact/dropdown";
import { stockStatuses } from "../../constants/Constants";
import { PrimeIcons } from "primereact/api";

const StockLevel = () => {
   const { state } = useLocation();

   const [shopProducts, setShopProducts] = useState([]);
   const [limit, setLimit] = useState(0);
   const [searchTerm, setSearchTerm] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [first, setFirst] = useState(0);
   const [totalRecords, setTotalRecords] = useState(0);
   const [shops, setShops] = useState([]);
   const [selectedShop, setSelectedShop] = useState(null);
   const [selectedLevel, setSelectedLevel] = useState(null);
   const [selectedManufacturer, setSelectedManufacturer] = useState(null);
   const [barcode, setBarCode] = useState("");

   let offset = 0;
   const toast = useRef(null);
   const dt = useRef(null);

   const isShopOwner = UserSessionUtils.getShopOwner();
   const isShopAttendant = UserSessionUtils.getShopAttendant();

   const fetchShopProducts = async () => {
      setIsLoading(true);
      const searchParameters = {
         offset: offset,
         limit: MAXIMUM_RECORDS_PER_PAGE,
         ...(searchTerm && { searchTerm }),
         ...(selectedManufacturer && { manufacturerId: selectedManufacturer?.id }),
         ...(isShopOwner && !selectedShop && { shopOwnerId: UserSessionUtils.getShopOwnerId() }),
         ...(isShopAttendant && { shopId: UserSessionUtils.getShopId() }),
         ...(selectedShop && { shopId: selectedShop?.id }),
         ...(barcode && { barCode: barcode }),
         ...(state?.id && { shopId: state?.id }),
         ...(selectedLevel && { levelType: selectedLevel.id }),
      };

      await new BaseApiService("/shop-products")
         .getRequestWithJsonResponse(searchParameters)
         .then(async (response) => {
            setShopProducts(response.records);
            setTotalRecords(response.totalItems);
            setIsLoading(false);
         })
         .catch((error) => {
            setIsLoading(false);
         });
   };

   const fetchShops = async () => {
      if (isShopAttendant == false) {
         let searchParameters = { offset: 0, limit: 0 };
         if (isShopOwner) {
            searchParameters.shopOwnerId = UserSessionUtils.getShopOwnerId();
         }
         new BaseApiService("/shops")
            .getRequestWithJsonResponse(searchParameters)
            .then(async (response) => {
               if (response.records?.length === 1) {
                  setSelectedShop(response.records[0]);
               }
               setShops(response.records);
            })
            .catch((error) => {});
      }
   };

   useEffect(() => {
      fetchShopProducts();
      fetchShops();
   }, []);

   const onPageChange = (e) => {
      offset = e.page * MAXIMUM_RECORDS_PER_PAGE;
      setFirst(e.first);
      setLimit(MAXIMUM_RECORDS_PER_PAGE);

      fetchShopProducts();
   };

   const productBodyTemplate = (rowData) => {
      return (
         <>
            {rowData.productName}
            <br />
            <span className="text-sm opacity-80">SN: {rowData.serialNumber}</span>
         </>
      );
   };

   const packedQtyBodyTemplate = (rowData) => {
      return <>{formatNumberWithCommas(Math.floor(rowData?.performanceSummary?.packedQuantity || 0))}</>;
   };

   const unPackedQtyBodyTemplate = (rowData) => {
      const packed = rowData?.performanceSummary?.packedQuantity || 0;
      const rem = packed - Math.trunc(packed || 0);
      return <>{formatNumberWithCommas(Math.round(rem * rowData?.packageQuantity))}</>;
   };

   const qtySoldBodyTemplate = (rowData) => {
      return <>{formatNumberWithCommas(rowData?.performanceSummary?.totalQuantitySold)}</>;
   };

   const remainingStockBodyTemplate = (rowData) => {
      return <>{formatNumberWithCommas(rowData?.performanceSummary?.remainingStockQuantity)}</>;
   };

   const header = (
      <div className="flex flex-column md:flex-row md:align-items-center">
         <h5 className="m-0 line relative">Stock levels</h5>
         <span className="block mt-2 md:mt-0 p-input-icon-left ml-5">
            <div className="p-inputgroup flex-1">
               <InputText placeholder="Search" onInput={(e) => setSearchTerm(e.target.value)} />
               <Button icon="pi pi-search" className="primary-btn" onClick={fetchShopProducts} />
            </div>
         </span>
      </div>
   );
   const updateProductSummaries = (productId) => {
      setIsLoading(true);
      new BaseApiService(`/shop-products/${productId}/update-summary`)
         .postRequestWithJsonResponse()
         .then((response) => {
            setIsLoading(false);
         })
         .catch((error) => {
            console.error(error);
            setIsLoading(false);
         });
   };
   const actionBodyTemplate = (rowData) => {
      return (
         <div className="actions flex md:flex-row md:align-items-center justify-content-center">
            <Button
               icon={PrimeIcons.REFRESH}
               loading={isLoading}
               title="Run reconciliation"
               className=" bg-gray-100 border-none hover:bg-yellow-200 "
               onClick={(r) => updateProductSummaries(rowData.id)}
            />
         </div>
      );
   };
   return (
      <div className="grid crud-demo">
         <div className="col-12">
            <div className="py-2">
               {shops?.length > 1 && (
                  <FilterComponent onFilter={fetchShopProducts} onClear={() => setSelectedShop(null)}>
                     <div className="formgrid grid">
                        <div className="field col-4">
                           <label htmlFor="shop">Shop</label>
                           <Dropdown
                              id="shop"
                              value={selectedShop}
                              options={shops}
                              onChange={(e) => setSelectedShop(e.target.value)}
                              optionLabel={"name"}
                              filter
                              showClear
                              filterBy="name"
                              placeholder="Select Shop"
                           />
                        </div>
                        <div className="field col-4">
                           <label htmlFor="shop">Level</label>
                           <Dropdown
                              id="shop"
                              value={selectedLevel}
                              options={stockStatuses}
                              onChange={(e) => setSelectedLevel(e.target.value)}
                              optionLabel={"name"}
                              filter
                              showClear
                              filterBy="name"
                              placeholder="Select Level"
                           />
                        </div>
                     </div>
                  </FilterComponent>
               )}
            </div>

            <div className="card">
               <Toast ref={toast} />

               {header}

               <DataTable
                  loading={isLoading}
                  ref={dt}
                  value={shopProducts} //to remove duplicates caused by multiple api calls
                  dataKey="id"
                  loadingIcon={PrimeIcons.SYNC}
                  rows={10}
                  className="datatable-responsive"
                  paginatorTemplate={template2}
                  emptyMessage="No stock levels found."
                  size="small"
                  resizableColumns
               >
                  <Column field="productName" header="Product" body={productBodyTemplate} style={{ width: "15%" }} />

                  {shops?.length > 1 && <Column header="Shop" field="shopName" />}

                  <Column header="In stock" body={remainingStockBodyTemplate} className="text-center" headerStyle={{ textAlign: "center" }} />

                  <Column
                     header="Stocked"
                     body={(data) => data?.performanceSummary?.totalQuantityStocked || 0}
                     className="text-center"
                     headerStyle={{ textAlign: "center" }}
                  />

                  <Column
                     field="packedQty"
                     body={packedQtyBodyTemplate}
                     header="Packed"
                     className="text-center"
                     headerStyle={{ textAlign: "center" }}
                  />
                  <Column
                     field="unpackedQty"
                     body={unPackedQtyBodyTemplate}
                     header="Unpacked"
                     className="text-center"
                     headerStyle={{ textAlign: "center" }}
                  />

                  <Column field="soldItems" header="Sold " body={qtySoldBodyTemplate} className="text-center" headerStyle={{ textAlign: "center" }} />
                  <Column body={actionBodyTemplate} header="Actions" className="text-center" />
               </DataTable>
               <Paginator
                  template={template2}
                  first={first}
                  rows={MAXIMUM_RECORDS_PER_PAGE}
                  totalRecords={totalRecords}
                  alwaysShow={false}
                  onPageChange={onPageChange}
               />
            </div>
         </div>
      </div>
   );
};
export default StockLevel;
